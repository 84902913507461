@import-normalize;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto';
}

#root {
  height: 100%;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.react-flow__edge-path-selector:hover {
  cursor: pointer;
}
.react-flow__edge-path-selector {
  fill: none !important;
  stroke: transparent !important;
  stroke-width: 28 !important;
}
